import React from "react";
import { graphql } from "gatsby";
import { Layout, Seo, Block, Error } from "@components";

const PageTemplate = ({ data: { page } }) => {
  const checkIfPageHasHero = () => {
    if (
      page?.pageBlocks?.pageBlocks[0]?.fieldGroupName ===
      "Page_Pageblocks_PageBlocks_Hero"
    ) {
      return {
        heightDesktop: page?.pageBlocks?.pageBlocks[0]?.heightDesktop,
        heightMobile: page?.pageBlocks?.pageBlocks[0]?.heightMobile,
      };
    }
    return false;
  };

  return (
    <Layout hasHero={checkIfPageHasHero()}>
      <Seo
        description={page?.seo?.metaDesc}
        meta={[
          { description: page?.seo?.metaDesc },
          { "og:title": page?.seo?.title },
          { "og:description": page?.seo?.openGraphDescription },
          { "og:type": page?.seo?.openGraphType },
          { "twitter:title": page?.seo?.twitterTitle },
          { "twitter:description": page?.seo?.twitterDescription },
        ]}
        title={page?.seo?.title}
        lang="en"
      />

      <article className="page" itemScope itemType="http://schema.org/Article">
        {page?.pageBlocks?.pageBlocks === null && (
          <Error title="Hmm - no content has been added for this page." />
        )}

        {page?.pageBlocks?.pageBlocks !== null &&
          page?.pageBlocks?.pageBlocks?.map((block, index) => {
            return (
              <Block
                pageTitle={page.title}
                pageCategories={page.categories}
                key={index}
                blockData={block}
              />
            );
          })}
      </article>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title

      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        twitterTitle
        twitterDescription
      }

      pageBlocks {
        pageBlocks {
          ... on WpPage_Pageblocks_PageBlocks_Hero {
            blockId
            paddingBottom
            paddingTop
            fieldGroupName
            scrollSpeed
            heroLogo
            heroMobile
            slides {
              altText
              mimeType
              videoDuration {
                videoDuration
                videoThumbnail {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        layout: FULL_WIDTH
                        breakpoints: [2400]
                        quality: 85
                      )
                    }
                  }
                }
              }
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                    breakpoints: [2400]
                    quality: 85
                  )
                }
              }
            }
            slidesMobile {
              altText
              mimeType
              videoDuration {
                videoDuration
                videoThumbnail {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        layout: FULL_WIDTH
                        breakpoints: [2400]
                        quality: 85
                      )
                    }
                  }
                }
              }
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                    breakpoints: [1400]
                    quality: 85
                  )
                }
              }
            }
          }

          ... on WpPage_Pageblocks_PageBlocks_TextBlock {
            blockId
            paddingBottom
            paddingTop
            fieldGroupName
            text
          }

          ... on WpPage_Pageblocks_PageBlocks_StaffBlock {
            blockId
            paddingBottom
            paddingTop
            fieldGroupName
            staffMembers {
              staffName
              staffRole
              staffImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }

          ... on WpPage_Pageblocks_PageBlocks_ServicesGrid {
            blockId
            gridColumn
            gridRow
            fieldGroupName
            paddingBottom
            paddingTop
            headingSize
            lineHeight
            serviceSize
            addServiceCategories {
              name
              databaseId
              categoryOrder {
                order
              }
              services {
                nodes {
                  title
                  menuOrder
                  featuredMedia {
                    selectFeaturedMediaType
                    featuredImage {
                      mediaDetails {
                        height
                        width
                      }
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                    }
                    featuredVideo {
                      mediaDetails {
                        width
                        height
                      }
                      localFile {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
